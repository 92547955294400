import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import firebaseAuth, { db } from './firebase';

async function getProfile(uid: string) {
  const docRef = doc(db, 'profile', uid);
  const docSnap = await getDoc(docRef);
  let profile = null;
  if (docSnap.exists()) {
    profile = docSnap.data();
  } else {
    console.log('No such document!');
  }
  if (profile) {
    delete profile.password;
  }
  return profile;
}

const formatAuthUser = (user: any, profile: any) => ({
  uid: user.uid,
  email: user.email,
  token: user.accessToken,
  role: profile?.role,
  org: profile?.org,
  name: profile?.name,
});

export default function useFirebaseAuth() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const clear = () => {
    setUser(null);
  };

  const authStateChanged = async (user: any) => {
    if (!user) {
      setUser(null);
      setLoading(false);
      return;
    }

    setLoading(true);
    let profile = null;
    if (user.accessToken) {
      try {
        profile = await getProfile(user.uid);
      } catch (error) {
        console.error('error getting profile', error);
      }
    }
    const formattedUser = formatAuthUser(user, profile);
    if (formattedUser.org === process.env.NEXT_PUBLIC_B2B_ORG) {
      setUser(formattedUser);
    } else {
      signOut(firebaseAuth).then(clear);
    }
    setLoading(false);
  };

  const login = async ({ email, password }: any) => {
    setLoading(true);
    try {
      const result = await signInWithEmailAndPassword(firebaseAuth, email, password);
      return true;
    } catch (error) {
      console.error('login error', error);
    } finally {
      setLoading(false);
    }
    return false;
  };

  const signUp = (email: string, password: string) =>
    createUserWithEmailAndPassword(firebaseAuth, email, password);

  const logOut = async () => {
    await signOut(firebaseAuth);
    clear;
  };

  const isSuperAdmin = (role: string) => {
    if (!role) {
      return false;
    }
    return role === 'SUPER_ADMIN';
  };

  const isAdmin = (role: string) => {
    if (!role) {
      return false;
    }
    return role === 'ADMIN' || isSuperAdmin(role);
  };

  // listen for Firebase state change
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, authStateChanged);
    return () => unsubscribe();
  }, []);

  return {
    user,
    loading,
    login,
    signUp,
    logOut,
    isAdmin,
    isSuperAdmin,
  };
}
